"use strict";
var protobuf = module.exports = require(17);

protobuf.build = "full";

// Parser
protobuf.tokenize         = require(34);
protobuf.parse            = require(26);
protobuf.common           = require(11);

// Configure parser
protobuf.Root._configure(protobuf.Type, protobuf.parse, protobuf.common);
